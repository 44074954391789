<template>
  <v-container fluid>
    <div class="my-5 mx-auto">
      <OnlineDeviceWM v-if="device.type === 1" :terminal="device.terminal" :device="device" />
      <OnlineDeviceAM v-else-if="device.type === 9" :terminal="device.terminal" :device="device" />
    </div>
  </v-container>
</template>

<script>
import CentrifugeApi from '@/services/CentrifugeApi'
import { isObjectEmpty } from '@/helpers/isObjectEmpty'

export default {
  name: 'DeviceOnline',

  components: {
    OnlineDeviceWM: () => import('@/components/common/online/OnlineDeviceWM'),
    OnlineDeviceAM: () => import('@/components/common/online/OnlineDeviceAM'),
  },

  props: {
    device: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data: () => ({
    ws: null,
    terminalSubscription: null,
  }),

  computed: {
    currentCompanyId() {
      return +this.$route.params.id
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.disconnectFromWs()
  },

  methods: {
    async initialize() {
      if (!this.ws) this.connectToWs()
    },
    async connectToWs() {
      // *** Initialize the Centrifuge connection
      this.ws = new CentrifugeApi()
      await this.ws.connectToCentrifuge()
      // *** Subscribe to terminal IMEIs
      this.terminalSubscription = this.ws.centrifuge.newSubscription(this.device.terminal.imei)
      this.terminalSubscription.on('publication', this.handlePublication)
      this.terminalSubscription.subscribe()
    },
    disconnectFromWs() {
      // *** Unsubscribe and disconnect from Centrifuge
      if (this.ws) {
        if (this.terminalSubscription) this.terminalSubscription.unsubscribe()
        this.ws.disconnectFromCentrifuge()
      }
    },
    handlePublication(ctx) {
      // *** Handle the publication data for a terminal
      if (!isObjectEmpty(this.device)) {
        const deviceState = ctx.data.devices.find((item) => item.id === this.device.id)
        this.$set(this.device, 'state', deviceState)
      }
    },
  },
}
</script>

<style scoped></style>
